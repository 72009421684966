<template >
  <div class="person">
    <div class="">
	   <personTable :isRecommendations="false"/>
    </div >
  </div >
</template >

<script >
import personTable from "./personTable/personTable.vue";
import {mapGetters} from "vuex";

export default {
  name: "Person",
  data() {
    return {};
  },
  components: {
    personTable,
  },
  computed: {
    ...mapGetters(["isAdmin"])
  }
};
</script >
