<template >
  <div class="personTable">
    <colel-card
	    :title="(!isRecommendations ? $t('personTable.title'): $t('personTable.titleRecommendations'))"
	    :isLoading="isLoading"
	    :icon="'mdi-account-supervisor-circle'"
    >
      <template v-slot:extra-header>
        <v-tooltip
		   bottom
		   v-if="!isRecommendations"
	   >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
			  @click="addPerson"
			  color="primary"
			  icon
			  v-bind="attrs"
			  v-on="on"
		  >
              <v-icon >mdi-plus-circle</v-icon >
            </v-btn >
          </template >
          <span >{{ $t("personTable.add") }}</span >
        </v-tooltip >
      </template >
      <template v-slot:content>
        <colel-table
		   :key="componentKey"
		   v-on:row-click="rowClick"
		   :value="pupilsSort"
		   :tableId="'person-data-state'"
		   :filters="filters"
		   :loading="isLoading"
		   :loadingTitle="$t(`personTable.loading`)"
		   :rowClass="rowClass"
		   :rows="pupilsActiveLength"
	   >
          <template v-slot:tableContent>
            <ColumnGroup
			  v-if="screenSize !== 'xs'"
			  type="header"
		  >
              <Row >
                <Column
				 columnStyle="text-align: rtl"
				 bodyStyle="text-align: right;"
				 header="פרטי האברך"
				 :colspan="5"
			 />
                <Column
				 headerStyle="text-align: rtl"
				 bodyStyle="text-align: right;"
				 header="כתובת"
				 :colspan="3"
			 />
                <Column
				 headerStyle="text-align: rtl"
				 bodyStyle="text-align: right;"
				 header="בנק"
				 :colspan="5"
			 />
              </Row >
              <Row >
                <Column
				 v-for="(header, index) in headersToShow"
				 :key="index"
				 :header="$t(`personTable.${header}`)"
				 :sortable="true"
				 :field="header"
			 />
              </Row >
              <Row >
                <Column
				 v-for="(header, index) in headersToShow"
				 :key="index"
				 :field="header"
			 >
                  <template #filter>
                    <InputText
					filter
					v-model="filters[header]"
					class="p-column-filter"

					:placeholder="
                        $t('personTable.filterBy', {
                          name: $t(`personTable.${header}`),
                        })
                      "
				/>
                  </template >
                </Column >
              </Row >
            </ColumnGroup >

            <Column
			  v-for="(header, index) in headersToShow"
			  :key="index"
			  :field="header"
			  :header="$t(`personTable.${header}`)"
			  headerStyle="text-align: rtl"
			  bodyStyle="text-align: right; overflow: visible"
			  sortable
			  :sortField="header"
			  :filterField="header"
			  filterMatchMode="contains"
		  >
              <template #filter>
                <InputText

				 filter
				 v-model="filters[header]"
				 class="p-column-filter"
				 :placeholder="
                    $t('personTable.filterBy', {
                      name: $t(`personTable.${header}`),
                    })
                  "
			 />
              </template >
            </Column >

            <Column
			  v-if="!isRecommendations"
			  headerStyle="width:1em"
			  bodyStyle="width:50px"

		  >
              <template #body="slotProps">
                <v-tooltip
				 bottom
				 v-if="slotProps.data.leaving_date === null"
			 >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
					@click="[askDeletePerson($event, slotProps), setSelected(slotProps)]"
					color="error"
					icon
					v-bind="attrs"
					v-on="on"
				>
                      <v-icon >mdi-trash-can</v-icon >
                    </v-btn >
                  </template >
                  <span >{{ $t("personTable.delete") }}</span >
                </v-tooltip >
                <v-tooltip
				 bottom
				 v-else
			 >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
					@click="restorePerson($event, slotProps)"
					color="success"
					icon
					v-bind="attrs"
					v-on="on"
				>
                      <v-icon >mdi-backup-restore</v-icon >
                    </v-btn >
                  </template >
                  <span >{{ $t("personTable.restore") }}</span >
                </v-tooltip >
              </template >
            </Column >

            <Column
			  v-if="isRecommendations"
			  headerStyle="width:1em"
			  bodyStyle="width:50px"
		  >
              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
					@click="rejectPerson($event, slotProps)"
					color="error"
					icon
					v-bind="attrs"
					v-on="on"
				>
                      <v-icon >mdi-close-box</v-icon >
                    </v-btn >
                  </template >
                  <span >{{ $t("personTable.reject") }}</span >
                </v-tooltip >
              </template >
            </Column >
            <Column
			  v-if="isRecommendations"
			  headerStyle="width:1em"
			  bodyStyle="width:50px"
		  >
              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
					@click="acceptPerson($event, slotProps)"
					color="success"
					icon
					v-bind="attrs"
					v-on="on"
				>
                      <v-icon >mdi-check-decagram</v-icon >
                    </v-btn >
                  </template >
                  <span >{{ $t("personTable.accept") }}</span >
                </v-tooltip >
              </template >
            </Column >

          </template >
        </colel-table >
      </template >
    </colel-card >
    <personDialog
	    ref="personDialog"
	    :person="selectedToEdit"
	    :isShow="dialog"
	    v-on:closeDialog="dialog = false"
	    v-on:updatePerson="updatePerson"
	    v-on:createPerson="createPerson"
    />
    <colel-delete-dialog
	    v-on:closeDialog="isDeleteDialog = false"
	    v-on:ok="deletePerson"
	    :isShow="isDeleteDialog"
	    :title="titleDeleteDialog"
    />
  </div >
</template >

<script>
import personDialog from "./personDialog/personDialog.vue";
import {dateToServer} from "@/utils/dateFormat";
import {canDeletePerson} from "@/utils/permissions.js";
import {
  acceptPerson,
  createPerson,
  createPersonRecommend,
  deletePerson,
  deletePersonRecommend,
  getAllPerson,
  rejectPerson,
  restorePerson,
  updatePerson,
  updatePersonRecommend
} from "./personTable";
import {mapGetters, mapState} from "vuex";
import i18n from "@/translate/translate";
import Vue from "vue";
import constant from "@/constants";
import constants from "@/constants";
import {sortByLastName, sortByLeaving} from "@/utils/sorts";

export default {
  name: "personTable",
  props: {
    isRecommendations: Boolean
  },
  components: {
    personDialog
  },
  data() {
    return {
      componentKey: 0,
      isDeleteDialog: false,
      titleDeleteDialog: null,
      dialog: false,
      deleteProp: null,
      selectedToDelete: null,
      filters: {},
      expandedRowGroups: null,
      isLoading: true,
      selectedToEdit: null,
      selectedToEdit2: null,
      headers: headers,
      data: [],
      selectedItemsTracing: []
    };
  },
  created() {
    this.getData()
  },
  methods: {
    isDifferent(originalObj, newObj) {
      let isDifferent = false;
      Object.keys(newObj).forEach(key => {
        if (newObj[key] !== originalObj[key]) {
          isDifferent = true;
        }
      });
      return isDifferent;
    },
    async getData() {
      try {
        const {data} = await getAllPerson()
        this.isLoading = false;
        this.data = data
      } catch (e) {
        console.log(e);
      }
    },
    rowClass(data) {
      let rowClass = "";
      data.leaving_date !== null ? rowClass += "row-unenabled" : null;
      return rowClass;
    },
    rowClick(e) {
      if (e.data.leaving_date === null) {
        this.selectedToEdit = {...e.data};
        this.dialog = true;
      }
    },
    setSelected(person) {
      this.selectedToEdit = {...person.data};
    },
    addPerson() {
      this.selectedToEdit = {};
      this.dialog = true;
    },
    askDeletePerson(e, props) {
      this.selectedToDelete = {...props.data};
      this.isDeleteDialog = true;
      this.titleDeleteDialog = i18n.t(`personTable.askDelete`, {
        first_name: this.selectedToDelete?.first_name,
        last_name: this.selectedToDelete?.last_name
      });
      e.stopPropagation();
    },
    specialError(e) {
	 if (e.response?.data) {
        switch (e.response?.data) {
          case 'duplicate key value violates unique constraint "person_pkey"':
            this.$toast.add({
                              severity: 'error',
                              summary: this.$t('errors.duplicatePersonId'),
                              life: constants.LIFE_TOAST
                            })
            return true
          case 'duplicate key value violates unique constraint "person_ez_number_key"':
            this.$toast.add({
                              severity: 'error',
                              summary: this.$t('errors.duplicateEz'),
                              life: constants.LIFE_TOAST
                            })
            return true
          default:
            return false
        }
      }
    },
    toastError(){
      this.$toast.add({
                        severity: 'error',
                        summary: this.$t('errors.???'),
                        life: constants.LIFE_TOAST
                      })
    },
    async createPerson(person) {
      if (this.isAdmin) {
        try {
          person.join_date = dateToServer(person.join_date)
          const result = await createPerson(person);
          if (result) {
            this.$toast.add({
                              severity: 'success',
                              detail: i18n.t(`editDialog.personAdded`, {
                                last_name: person.last_name,
                                first_name: person.first_name
                              }),
                              life: constant.LIFE_TOAST
                            });
          }
          person.leaving_date = null
          this.data.unshift(person)
          this.dialog = false
          this.$refs.personDialog.resetForm();

        } catch (e) {
          if (!this.specialError(e)) {
            this.$toast.add({
                              severity: 'error',
                              detail: i18n.t(`editDialog.personCreateError`, {
                                last_name: e.last_name,
                                first_name: e.first_name
                              }),
                              life: constant.LIFE_TOAST
                            });
          }
        }
      } else {
        try {
          const isPersonAlreadyExist = this.data.find(p => p.id === person.id);
          if (!isPersonAlreadyExist) {
            person.join_date = dateToServer(person.join_date)
            const result = await createPersonRecommend(person);
            if (result) {
              this.$toast.add({
                                severity: 'success',
                                summary: this.$t(
                                    'suggestions.savedSuccessfully'),
                                life: constants.LIFE_TOAST
                              });
              this.dialog = false
              this.$refs.personDialog.resetForm();

            }
          }
        } catch (e) {
          if (!this.specialError(e)) {
            this.$toast.add({
                            severity: 'error',
                            detail: i18n.t(`editDialog.personCreateError`, {
                              last_name: e.last_name,
                              first_name: e.first_name
                            }),
                            life: constant.LIFE_TOAST
                          });
        }
        }
      }
    },
    async updatePerson(e) {
      const originalPerson = this.data.find(person => person.id === e.id);
      if (this.isAdmin) {
        try {
          e.join_date = dateToServer(e.join_date)

          const result = await updatePerson(e);
          if (result) {
            this.$toast.add({
                              severity: 'success',
                              detail: i18n.t(`editDialog.personEdited`, {
                                last_name: e.last_name,
                                first_name: e.first_name
                              }),
                              life: constant.LIFE_TOAST
                            });
            this.dialog = false
            this.$refs.personDialog.resetForm();

          }
          const index = this.data.findIndex(person => person.id === e.id)
          Vue.set(this.data, index,
                  JSON.parse(JSON.stringify(e)));
        } catch (e) {
          if (!this.specialError(e)) {

            this.$toast.add({
                              severity: 'error',
                              detail: i18n.t(`editDialog.personEditedError`, {
                                last_name: e.last_name,
                                first_name: e.first_name
                              }),
                              life: constant.LIFE_TOAST
                            });
          }
        }
      } else {
        if (this.isDifferent(originalPerson, e)) {
          e.join_date = dateToServer(e.join_date)
          const result = await updatePersonRecommend(e);
          if (result) {
            this.$toast.add({
                              severity: 'success',
                              summary: this.$t('suggestions.savedSuccessfully'),
                              life: constants.LIFE_TOAST
                            });
            this.dialog = false
            this.$refs.personDialog.resetForm();

          }
        }
      }
    },
    async restorePerson(e, props) {
      e.stopPropagation();
      try {
        const data = await restorePerson(props.data.id);
        this.data.find(person => person.id === props.data.id).leaving_date =
            null
      } catch (e) {
        console.log(e);
      }
    },
    async deletePerson(date) {
      if (this.isAdmin) {
        try {
          const result = await deletePerson(this.selectedToDelete.id, date);
          if (result) {
            this.data.find(
                person => person.id === this.selectedToDelete.id).leaving_date =
                new Date()
            this.$toast.add({
                              severity: 'success',
                              detail: i18n.t(`deleteDialog.personDeleted`, {
                                last_name: this.selectedToDelete?.last_name,
                                first_name: this.selectedToDelete?.first_name
                              }),
                              life: constant.LIFE_TOAST
                            });
          }
        } catch (e) {
          console.log(e);
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t('errors.error'),
                            detail:
                                this.$t('errors.internalServerError'),
                            life: constant.LIFE_TOAST
                          });
        }
      } else {
        try {
          if (date !== this.selectedToEdit.suggestedToDelete) {
            const result = await deletePersonRecommend(
                {...this.selectedToDelete, leaving_date: date});
            this.data.find(person => person.id ===
                this.selectedToDelete.id).suggestedToDelete = date;
            if (result) {
              this.$toast.add({
                                severity: 'success',
                                summary: this.$t(
                                    'suggestions.savedSuccessfully'),
                                life: constants.LIFE_TOAST
                              });
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    rejectPerson(e, props) {
      e.stopPropagation();
      rejectPerson(props.data);
    },
    acceptPerson(e, props) {
      e.stopPropagation();
      acceptPerson(props.data);
    }
  },
  computed: {
    pupilsActiveLength() {
	 return this.pupilsSort.filter(p => p.leaving_date === null).length
    },
    pupilsSort() {
	 return this.data.slice().sort(
       (curr, next) => sortByLastName(curr, next, true)).sort(
       (curr, next) => sortByLeaving(curr, next))
       .filter(person => person.colel_id === this.colelId)
    },
    screenSize() {
      return this.$vuetify.breakpoint.name
    },
    headersToShow() {
      if (this.$vuetify.breakpoint.name !== 'xs') {

        if (this.isAdmin) {
          return headers
        } else {
          return headersUser
        }
      } else {
        return headersMobile
      }
    },
    canDeletePerson() {
      return canDeletePerson(this.user);
    },
    ...mapState(["user"]),
    ...mapGetters(["isAdmin", "colelId"])
  }
};


const headers = [
  "colel_id",
  "last_name",
  "first_name",
  "phone_number",
  "id",
  "street",
  "apartment",
  "city",
  "bank_name",
  "bank_branch",
  "bank_account",
  "name_to_pay_for",
  "ez_number",
  "join_date",
  "leaving_date"
];

const headersUser = [
  "last_name",
  "first_name",
  "phone_number",
  "id",
  "street",
  "apartment",
  "city",
  "bank_name",
  "bank_branch",
  "bank_account",
  "name_to_pay_for",
  "join_date",
  "leaving_date"
];

const headersMobile = [
  "last_name",
  "first_name"
];

</script >

<style lang="scss">
.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
}

.p-column-filter {
  width: 100%;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  //display: none;
}

@media screen and (max-width: 40em) {
  //.p-datatable {
  //  &.p-datatable-responsive-demo {
  //    .p-datatable-thead > tr > th,
  //    .p-datatable-tfoot > tr > td {
  //      display: none !important;
  //    }
  //
  //    .p-datatable-tbody > tr > td {
  //      text-align: left;
  //      display: block;
  //      width: 100%;
  //      float: left;
  //      clear: left;
  //      border: 0 none;
  //
  //      .p-column-title {
  //        padding: 0.4rem;
  //        min-width: 30%;
  //        display: inline-block;
  //        margin: -0.4em 1em -0.4em -0.4rem;
  //        font-weight: bold;
  //      }
  //
  //      &:last-child {
  //        border-bottom: 1px solid var(--surface-d);
  //      }
  //    }
  //  }
  //}
}
</style >
