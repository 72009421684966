/* eslint-disable no-unused-vars */
<template >
  <div class="peersonDialog">
    <v-dialog
	    style="z-index: 1030"
	    v-model="isShow"
	    max-width="600"
	    v-on:click:outside="closeDialog"
    >
      <colel-card
		 zIndex="9999"
		 :title="$t('personDialog.title')"
		 :icon="'mdi-card-account-details-outline'"
	 >
        <template v-slot:content>
          <validation-observer
			ref="person"
			v-slot="{ invalid }"
		>
            <form @submit.prevent="submit">
              <v-card-text >
                <v-container >
                  <v-row >
                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.last_name`)"
					  rules="required"
				  >
                        <v-text-field
					    v-model="personObj.last_name"
					    :error-messages="errors"
					    :label="$t(`personTable.last_name`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.first_name`)"
					  rules="required"
				  >
                        <v-text-field
					    v-model="personObj.first_name"
					    :error-messages="errors"
					    :label="$t(`personTable.first_name`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.first_name`)"
					  rules="required"
				  >
                        <v-text-field
					    v-model="personObj.phone_number"
					    :error-messages="errors"
					    :label="$t(`personTable.phone_number`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.id`)"
					  :rules="{
                          required: true,
                           regex: person.id !== undefined ? /.*?/ :    /^[0-9]{9}$/ ,
                           // regex: /^[0-9]{9}$/,
                        }"
				  >
                        <v-text-field

					    :disabled="person.id !== undefined"
					    v-model="personObj.id"
					    :error-messages="errors"
					    :label="$t(`personTable.id`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >

                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
				    <div class="field col-12 ">
                <label for="join-date">{{
				    $t('personTable.join_date')
							    }}</label >
                <colel-calendar

				 id="join-date"
				 :yearNavigator="true"
				 yearRange="2000:2030"
				 :monthNavigator="true"
				 :manualInput="false"
				 :dateFormat="dateformat"
				 touchUI
				 v-model="personObj.join_date"
				 :showWeek="false"
			 />
            </div >

                    </v-col >
				   <v-col
					   v-if="isAdmin"
					   cols="12 center"

					   xs="12"
					   sm="5"
				   >
				    <div class="field col-12 ">
                      <validation-provider
					  id="colel_id"

					  v-slot="{ errors }"
					  :name="$t(`personTable.colel_id`)"

				  >
                        <Dropdown

					    :showClear="true"
					    style=" width:100%"
					    :filter="true"
					    v-model="personObj.colel_id"
					    :options="colelIds"
					    :error-messages="errors"
					    :placeholder="$t(`personTable.colel_id`)"
				    />

                      </validation-provider >
				    </div >
                    </v-col >
                    <v-col cols="12">
                      <v-divider class="mx-4"></v-divider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.street`)"
					  :rules="{
                          required: true,
                          alpha_spaces: true
                        }"

				  >
                        <v-text-field
					    v-model="personObj.street"
					    :error-messages="errors"
					    :label="$t(`personTable.street`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="2"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.apartment`)"
					  :rules="{
                          required: true,
                          numeric: true
                        }"
				  >
                        <v-text-field
					    v-model="personObj.apartment"
					    :error-messages="errors"
					    :label="$t(`personTable.apartment`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.city`)"
					  rules="required"
				  >
                        <v-text-field
					    v-model="personObj.city"
					    :error-messages="errors"
					    :label="$t(`personTable.city`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col cols="12">
                      <v-divider class="mx-4"></v-divider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="4"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.bank_name`)"
					  rules="required"
				  >
                        <v-text-field
					    v-model="personObj.bank_name"
					    :error-messages="errors"
					    :label="$t(`personTable.bank_name`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="2"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.bank_branch`)"
					  :rules="{
                          required: true,
                          numeric: true
                        }"
				  >
                        <v-text-field
					    v-model="personObj.bank_branch"
					    :error-messages="errors"
					    :label="$t(`personTable.bank_branch`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="3"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.bank_account`)"
					  rules="required"
				  >
                        <v-text-field
					    v-model="personObj.bank_account"
					    :error-messages="errors"
					    :label="$t(`personTable.bank_account`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					v-if="isAdmin"
					cols="12"
					xs="12"
					sm="3"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.ez_number`)"
					  :rules="{
                          required: true,
                          regex: '^[0-9]*$',
                        }"
				  >
                        <v-text-field
					    v-model="personObj.ez_number"
					    :error-messages="errors"
					    :label="$t(`personTable.ez_number`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="5"
				>
                      <validation-provider
					  v-slot="{ errors }"
					  :name="$t(`personTable.name_to_pay_for`)"
					  rules="required"
				  >
                        <v-text-field
					    v-model="personObj.name_to_pay_for"
					    :error-messages="errors"
					    :label="$t(`personTable.name_to_pay_for`)"
				    ></v-text-field >
                      </validation-provider >
                    </v-col >


                  </v-row >
                </v-container >
              </v-card-text >
              <v-card-actions >
                <v-spacer ></v-spacer >
                <v-btn
				 color="blue darken-1"
				 text
				 @click="closeDialog"
			 >
                  {{ $t("personDialog.cancel") }}
                </v-btn >
                <v-btn
				 type="submit"
				 :disabled="invalid"
				 color="blue darken-1"
				 text
			 >{{ $t("personDialog.post") }}
                </v-btn >
              </v-card-actions >
            </form >
          </validation-observer >
        </template >
      </colel-card >
    </v-dialog >
  </div >
</template >

<script >
// eslint-disable-next-line no-unused-vars
import {required, digits,alpha_spaces, alpha, email, numeric, max, regex} from
      "vee-validate/dist/rules";
// eslint-disable-next-line no-unused-vars
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import i18n from "@/translate/translate";
import {mapGetters, mapState} from "vuex";
import {dateToFullHebrew} from "@/utils/dateFormat";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} צריך להיות מספר.  ({_value_})"
});

extend("numeric", {
  ...numeric,
  message: "{_field_} צריך להיות מספר"
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "{_field_} צריך להיות רק אותיות"
});

extend("required", {
  ...required,
  message: i18n.t(`form.required`)
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});

extend("regex", {
  ...regex,
  message: i18n.t(`form.regex`)
});

extend("email", {
  ...email,
  message: "Email must be valid"
});

export default {
  name: "personDialog",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    isShow: Boolean,
    person: Object
  },

  data() {
    return {
      date: new Date(),
      menu: false,
      modal: false,
      showDialog: false,
      menu2: false,
      valid: false,
      personObj: {}
    };
  },

  watch: {
    person(newPerson) {

      if (newPerson.id) {
        this.personObj = newPerson;
        setTimeout(() => {
          this.$refs.person.validate();

        }, 500)

      } else {
        this.personObj = {};
      }
    }
  },
  methods: {

    closeDialog() {
      this.resetForm()
      this.$emit("closeDialog");
    },
    resetForm() {
      this.personObj = {}
      this.$refs.person.reset();
      this.date = new Date();
    },
    submit() {
      this.date = new Date();
      this.$refs.person.validate();
      if (this.person.id) {
        this.$emit("updatePerson", this.personObj);
      } else {
        this.$emit("createPerson", {
          ...this.personObj,
          join_date: this.personObj.join_date || this.date
        });
      }
      // this.$refs.person.reset();
      // this.personObj = {};
      // this.$emit("closeDialog");
    }

  },
  computed: {
    dateformat() {
      return dateToFullHebrew(this.personObj.join_date);
    },
    ...mapState(['colelIds']),
    ...mapGetters(["isAdmin"])
  },
  mounted() {
    document.addEventListener("keypress", (event) => {
      if (event.key === "Enter" && this.isShow) {
        event.preventDefault();
        this.submit()
      }
    });
  }
};
</script >

<style scoped>
#colel-pick {
    /*width: 35vw;*/
}
>>> .v-dialog--active{
    z-index: 99975 !important;
}
>>> .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    /*padding-right: 0 !important;*/
    margin-right: 0 !important;
}
</style >
