var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"personTable"},[_c('colel-card',{attrs:{"title":(!_vm.isRecommendations ? _vm.$t('personTable.title'): _vm.$t('personTable.titleRecommendations')),"isLoading":_vm.isLoading,"icon":'mdi-account-supervisor-circle'},scopedSlots:_vm._u([{key:"extra-header",fn:function(){return [(!_vm.isRecommendations)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.addPerson}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,false,2333170902)},[_c('span',[_vm._v(_vm._s(_vm.$t("personTable.add")))])]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('colel-table',{key:_vm.componentKey,attrs:{"value":_vm.pupilsSort,"tableId":'person-data-state',"filters":_vm.filters,"loading":_vm.isLoading,"loadingTitle":_vm.$t("personTable.loading"),"rowClass":_vm.rowClass,"rows":_vm.pupilsActiveLength},on:{"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"tableContent",fn:function(){return [(_vm.screenSize !== 'xs')?_c('ColumnGroup',{attrs:{"type":"header"}},[_c('Row',[_c('Column',{attrs:{"columnStyle":"text-align: rtl","bodyStyle":"text-align: right;","header":"פרטי האברך","colspan":5}}),_c('Column',{attrs:{"headerStyle":"text-align: rtl","bodyStyle":"text-align: right;","header":"כתובת","colspan":3}}),_c('Column',{attrs:{"headerStyle":"text-align: rtl","bodyStyle":"text-align: right;","header":"בנק","colspan":5}})],1),_c('Row',_vm._l((_vm.headersToShow),function(header,index){return _c('Column',{key:index,attrs:{"header":_vm.$t(("personTable." + header)),"sortable":true,"field":header}})}),1),_c('Row',_vm._l((_vm.headersToShow),function(header,index){return _c('Column',{key:index,attrs:{"field":header},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('personTable.filterBy', {
                          name: _vm.$t(("personTable." + header)),
                        })},model:{value:(_vm.filters[header]),callback:function ($$v) {_vm.$set(_vm.filters, header, $$v)},expression:"filters[header]"}})]},proxy:true}],null,true)})}),1)],1):_vm._e(),_vm._l((_vm.headersToShow),function(header,index){return _c('Column',{key:index,attrs:{"field":header,"header":_vm.$t(("personTable." + header)),"headerStyle":"text-align: rtl","bodyStyle":"text-align: right; overflow: visible","sortable":"","sortField":header,"filterField":header,"filterMatchMode":"contains"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('personTable.filterBy', {
                      name: _vm.$t(("personTable." + header)),
                    })},model:{value:(_vm.filters[header]),callback:function ($$v) {_vm.$set(_vm.filters, header, $$v)},expression:"filters[header]"}})]},proxy:true}],null,true)})}),(!_vm.isRecommendations)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.leaving_date === null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){[_vm.askDeletePerson($event, slotProps), _vm.setSelected(slotProps)]}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("personTable.delete")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.restorePerson($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("personTable.restore")))])])]}}],null,false,1132668986)}):_vm._e(),(_vm.isRecommendations)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.rejectPerson($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-box")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("personTable.reject")))])])]}}],null,false,1718570105)}):_vm._e(),(_vm.isRecommendations)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.acceptPerson($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-decagram")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("personTable.accept")))])])]}}],null,false,2265041885)}):_vm._e()]},proxy:true}])})]},proxy:true}])}),_c('personDialog',{ref:"personDialog",attrs:{"person":_vm.selectedToEdit,"isShow":_vm.dialog},on:{"closeDialog":function($event){_vm.dialog = false},"updatePerson":_vm.updatePerson,"createPerson":_vm.createPerson}}),_c('colel-delete-dialog',{attrs:{"isShow":_vm.isDeleteDialog,"title":_vm.titleDeleteDialog},on:{"closeDialog":function($event){_vm.isDeleteDialog = false},"ok":_vm.deletePerson}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }